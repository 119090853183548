export default function filters() {
    const $form = $('#shop_filters'),
        $sidebarShop = $('#sidebar_shop'),
        $list = $('#products_container'),
        $preloader = $('.preloader'),
        $pagination = $('#pagination_container');

    let checkedState = {};

    $(document).on('click', '.js-wc-pagination', function (e) {
        e.preventDefault();

        var $page = $(this).data('page');

        $('input[name=paged]').attr('value', $page);

        var data = $('#shop_filters').serialize();

        handleForm(e, data);
    });

    $(document).on('change', '.js-parent-filter', function (e) {
        let checkedKey = $(this).data('checked');
        checkedState[checkedKey] = this.checked;
    }); 

    $(document).on('click', '.js-filter-accept', function(e){
        e.preventDefault();

        var data = $(this).closest('#shop_filters').serialize();

        handleForm(e, data);
    });

    $(document).on('submit', '#shop_filters', function(e){
        e.preventDefault();

        console.log('test');

        var data = $(this).closest('#shop_filters').serialize();

        handleForm(e, data);
    });


    $(document).on('click', '.js-wc-attr-filter', function(){
        let checkedKey = $(this).data('checked');
        checkedState[checkedKey] = this.checked;
    });

    $(document).on('change', '.js-wc-filter', function (e) {
        var data = $(this).closest('#shop_filters').serialize();

        let checkedKey = $(this).data('checked');
        checkedState[checkedKey] = this.checked;

        handleForm(e, data);
    });

    $(document).on('click', '.js-filters-clear', function (e) {
        $('input[type="checkbox"]').prop('checked', false);

        $('[aria-expanded]').prop('aria-expanded', false);

        $('.shop-filter__subcategory').removeClass('show');

        var data = $('#shop_filters').serialize();

        checkedState = [];

        handleForm(e, data);

        replaceCurrentUrlWithoutQueryParams();
    });

    function replaceCurrentUrlWithoutQueryParams() {
        const currentUrl = location.href;
        if (currentUrl.indexOf('?') !== -1) {
            const [urlWithoutQueryParams] = currentUrl.split('?');
            history.replaceState(null, null, urlWithoutQueryParams);
        }
    }

    function restoreCheckedState() {
        for (let checkedKey in checkedState) {
            const checkbox = $(
                'input[type="checkbox"][data-checked="' + checkedKey + '"]'
            );

            checkbox.prop('checked', checkedState[checkedKey]);

            const accordionButton = checkbox.closest('.js-parent-div');

            accordionButton.attr('aria-expanded', checkedState[checkedKey]);

            const targetId = accordionButton.attr('data-target');

            if (checkedState[checkedKey]) {
                $(targetId).addClass('show');
            } else {
                $(targetId).removeClass('show');
            }
        }
    }

    function handleForm(e, data) {
        e.preventDefault();

        // pushFormDataToHistory(data);

        var $selected_filters = $('.js-wc-filter:checked');
        var selectedAttrs = [];

        $selected_filters.each(function () {
            var attr = $(this).attr('data-name');
            var value = $(this).attr('value');
            selectedAttrs.push([attr, value]);
        });

        var $taxonomy = $('.js-tax-info').data('taxonomy');
        var $tax_id = $('.js-tax-info').data('taxonomy-id');
        var $currentLang = $('#products_container').data('lang');

        if ($taxonomy) {
            data += '&taxonomy_id=' + $tax_id;
            data += '&taxonomy=' + $taxonomy;
        }

        data += '&action=get_products_list';

        data += '&current_lang=' + $currentLang;

        if (selectedAttrs.length > 0) {
            var attrs = $.param({ 'attrs[]': selectedAttrs }, true);
            data += '&' + attrs;
        }

        $.ajax({
            url: ajax.url,
            method: 'POST',
            data: data,
            beforeSend() {
                $preloader.addClass('-active');
                let header = document.getElementById('scroll_in_to_view');
                if(header){
                    const headerPosition = header.getBoundingClientRect().top + window.pageYOffset;
                    window.scrollTo({
                        top: headerPosition - 150,
                        behavior: 'smooth'
                    });
                }
            },
            success(response) {
                $list.html(response['products']).hide().fadeIn();
                $sidebarShop.html(response['searchbar']).hide().fadeIn();
                $pagination.html(response['pagination']).hide().fadeIn();
                restoreCheckedState();
                $('.js-result-count')
                    .html(response['result-count'])
                    .hide()
                    .fadeIn();
            },
            error(error) {
                console.log('error.response', error.response);
            },
            complete() {
                $preloader.removeClass('-active');
            },
        });
    }

    $(document).on('click', '.js-wc-filter-remove', function (e) {
        var $this = $(this);
        var $value = $this.data('attr');

        $this.remove();

        var $selected_filters = $('.js-wc-filter:checked');

        $selected_filters
            .filter(function () {
                return $(this).val() == $value;
            })
            .prop('checked', false);

        var data = $('#shop_filters').serialize();

        handleForm(e, data);
    });
}

export function pushFormDataToHistory(formData) {
    const searchParams = new URLSearchParams(formData);

    window.history.pushState(
        null,
        '',
        window.location.pathname + '?' + searchParams.toString()
    );
}
