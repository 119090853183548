export default function footerMenu() {
    $ = jQuery;

    var $windowSize = $(document).width();

    if ($windowSize <= 991) {
        $('.collapse').collapse('dispose');
    } else {
        $('.js-collapse').attr('id', '');
    }

    // Strzałka przenosząca na samą górę strony
    let $footerArrow = $('.footer__arrow');

    $(document).ready(function () {
        $footerArrow.click(function () {
            $('html, body').animate({ scrollTop: 0 }, 'slow');
            return false;
        });
    });
}
