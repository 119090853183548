export default function search() {
    $ = jQuery;

    var $search = $('.js_header_search'),
        $searchForm = $('.js_header_search_form'),
        $searchInput = $('.js_header_search_input'),
        $searchResults = $('.js_header_search_results'),
        $searchSubmit = $('.js_header_search_submit'),
        searchActiveClass = 'is-active';

    // $searchForm.on('submit', function (e) {
    //     e.preventDefault();
    // });

    $searchSubmit.on('click', function () {
        console.log('click');
        $(this).closest('.js_header_search').addClass();
        if ($(this).closest('.js_header_search').hasClass('is-active')) {
            $(this).closest('.js_header_search').removeClass('is-active');
            $(this)
                .closest('.js_header_search')
                .find('.js_header_search_results')
                .removeClass('is-visible');
            $(this)
                .closest('.js_header_search')
                .find('.js_header_search_input')
                .removeClass('is-active');
            $(this)
                .closest('.js_header_search')
                .find('.js_header_search_input')
                .val('');
        } else {
            $(this).closest('.js_header_search').addClass('is-active');
            $(this)
                .closest('.js_header_search')
                .find('.js_header_search_input')
                .get(0)
                .focus();
        }
    });

    $searchInput.on('keyup', function (e) {
        handleSearchAjax($(this));
    });

    document.addEventListener('click', function (event) {
        if($search){
        if (
            $search.get(0) !== event.target &&
            !$search.get(0).contains(event.target)
        ) {
            $search.removeClass(searchActiveClass);
            $search.val('');
            $searchResults.removeClass('is-visible');
            $searchInput.removeClass('is-active');
        }
    }
    });

    $('body').click(function (event) {
        if($search){
        if (
            $search.get(0) !== event.target &&
            !$search.get(0).contains(event.target)
        ) {
            $search.removeClass(searchActiveClass);
            $search.val('');
            $searchResults.removeClass('is-visible');
            $searchInput.removeClass('is-active');
        }
    }
    });

    $($search).click(function (e) {
        e.stopPropagation();
    });

    function handleSearchAjax(query) {
        var queryVal = query.val();
        $searchResults = query
            .closest('.js_header_search')
            .find('.js_header_search_results');
        $searchInput = query
            .closest('.js_header_search')
            .find('.js_header_search_input');

        if (!queryVal) {
            $searchResults.empty();
            $searchResults.removeClass('is-visible');
            $searchInput.removeClass('is-active');
            return;
        }

        $.ajax({
            url: ajax.url,
            method: 'POST',
            data: {
                action: 'search',
                query: queryVal,
            },
        })
            .then(function (response, status, xhr) {
                $searchResults.empty();
                $searchResults.addClass('is-visible');
                $searchInput.addClass('is-active');

                if (status == 'success' && response.success == true) {
                    $searchResults.html(response.data.content);
                }
            })
            .catch(function (error) {
                $searchResults.empty();

                handleError(error);
            });
    }

    function handleError(error) {}
}
