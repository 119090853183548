export default function SmoothScroll() {
    $(document).on('click', 'a[href^="#"]', function (event) {
        event.preventDefault();

        $('html, body').animate(
            {
                scrollTop: $($.attr(this, 'href')).offset().top - 120,
            },
            500
        );
    });
}
